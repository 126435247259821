<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Housekeeping Assignment Calendar') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div
            v-if="hasRoomId"
            class="column is-hidden-tablet"
          >
            <a
              class="has-text-weight-bold has-text-primary"
              @click="$refs.searchForm.setRoomId(undefined)"
            >
              <IconBase width="14" height="12" icon-name="back" class="m-b-0 m-r-2x">
                <IconBack />
              </IconBase>
              <span>{{ $t('Back to all rooms') }}</span>
            </a>
          </div>
          <div class="column is-narrow">
            <h3 class="has-text-primary has-text-weight-bold is-size-5 m-b-3x">
              {{ $t('Filter') }}
            </h3>
            <SearchForm
              ref="searchForm"
              date-format="DD MMMM YYYY"
              :current-date="dateFrom"
              @filter="fetchData(true)"
            />
          </div>
          <div class="column">
            <div class="columns is-vcentered">
              <div
                v-if="hasRoomId"
                class="column is-hidden-mobile"
              >
                <a
                  class="has-text-weight-bold has-text-primary"
                  @click="$refs.searchForm.setRoomId(undefined)"
                >
                  <IconBase width="14" height="12" icon-name="back" class="m-b-0 m-r-2x">
                    <IconBack />
                  </IconBase>
                  <span>{{ $t('Back to all rooms') }}</span>
                </a>
              </div>
              <div class="button-group column columns is-justify-content-flex-end">
                <div class="column is-narrow pr-0">
                  <div v-permission="['add-reservation']" class="has-text-right">
                    <BlockDateAdd v-draggable :draggable="true" @close="fetchData(true)" />
                  </div>
                </div>
                <div class="column is-narrow pr-0">
                  <div v-permission="['add-reservation']" class="has-text-right">
                    <ReservationAdd @close="fetchData(true)" />
                  </div>
                </div>
                <div class="column is-narrow pr-0">
                  <div v-permission="['add-room-assignment']" class="has-text-right">
                    <RoomAssignmentAdd v-draggable :draggable="true" @close="fetchData(true)" />
                  </div>
                </div>
              </div>
            </div>
            <PerRoomCalendar
              v-if="hasRoomId && init"
              :date-from="dateFrom"
              :is-fetching="isFetching"
              :list="list"
              @change="(date)=>onDateChange(date, true)"
            />
            <CalendarTable
              v-if="!hasRoomId"
              :init="init"
              :is-fetching="isFetching"
              :date-from="dateFrom"
              :date-to="dateTo"
              :days="days"
              :list="list"
              :scroll="scroll"
              @change="onDateChange"
              @select-room="setRoomId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Housekeeping Assignment Calendar": "Housekeeping Assignment Calendar",
    "Filter": "Filter",
    "Back to all rooms":"Back to all rooms"
  },
  "ja": {
    "Housekeeping Assignment Calendar": "Housekeeping Assignment Calendar",
    "Filter": "フィルター",
    "Back to all rooms":"オールルーム"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import SearchForm from '@/views/calendar/components/SearchForm';
import CalendarTable from '@/views/calendar/components/CalendarTable';
import RoomAssignmentAdd from '@/views/room-assignment/components/RoomAssignmentAddModal';
import ReservationAdd from '@/views/reservations/components/ReservationAddModal';
import BlockDateAdd from '@/views/calendar/components/BlockDateAddModal';
import PerRoomCalendar from '@/views/calendar/components/PerRoomCalendar';
import IconBack from '@/components/icons/IconBack';

export default {
  name: 'Calendar',
  components: {
    IconBack,
    PerRoomCalendar,
    CalendarTable,
    SearchForm,
    RoomAssignmentAdd,
    ReservationAdd,
    BlockDateAdd,
  },
  data() {
    return {
      list: undefined,
      isFetching: false,
      days: 0,
      partnerList: [],
      init: false,
      scroll: {
        x: 0,
        y: 475,
      },
      horizontalScrollInc: 990,
    };
  },
  computed: {
    hasRoomId() {
      return !!this.$route.query.roomId;
    },
    dateFrom() {
      return this.$route.query.date
        ? this.$route.query.date
        : dayjs(new Date()).format('YYYY-MM-DD');
    },
    dateTo() {
      return dayjs(this.dateFrom)
        .add(this.days - 1, 'days')
        .format('YYYY-MM-DD');
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'room-assignment') window.location.reload();
    },
  },
  created() {
    // if (this.$mq === 'mobile') this.horizontalScrollInc = 990;
  },
  methods: {
    /**
     * initial fetchData call will happen after SearchForm.vue component is created
     * called through filter emit on SearchForm.vue
     */
    fetchData() {
      this.init = true;
      if (this.list === undefined) {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
      }
      // this.list = undefined;
      this.isFetching = true;

      let dateFrom;
      let dateTo;
      if (this.hasRoomId) { // prev/current/next month's data
        dateFrom = dayjs(this.dateFrom).startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
        dateTo = dayjs(this.dateFrom).endOf('month').add(1, 'month').format('YYYY-MM-DD');
      } else { // date range data
        dateFrom = dayjs(this.dateFrom).subtract(15, 'd').format('YYYY-MM-DD');
        dateTo = dayjs(this.dateFrom).add(15, 'd').format('YYYY-MM-DD');
      }

      const payload = {
        dateFrom,
        dateTo,
        propertyId: this.$route.query.propertyId ? this.$route.query.propertyId : undefined,
        roomId: this.$route.query.roomId ? this.$route.query.roomId : undefined,
        status: this.$route.query.status ? this.$route.query.status : undefined,
        reservation: this.$route.query.reservation ? this.$route.query.reservation : undefined,
      };
      this.$store
        .dispatch('calendar/list', { params: payload })
        .then((resp) => {
          this.days = 14;
          this.scroll.x = this.horizontalScrollInc;
          this.list = resp.list;
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
          this.isFetching = false;
        });
    },
    onDateChange() {
      this.fetchData();
    },
    setRoomId(id) {
      if (this.$refs?.searchForm) {
        this.$refs.searchForm.setRoomId(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
}

@media screen and (max-width: 768px) {
  h3.m-b-3x {
    margin-bottom: 5px!important;
  }
  .section {
    padding: 5px 15px 15px 15px;
  }
  .button-group {
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -25px;
    margin-bottom: -15px;
  }
  .button-group .column {
    padding: 5px!important;
  }
  .button-group .column:nth-child(1) {
    order: 3;
    flex: 0 0 100%;
    :deep .ant-btn {
      width: 100%;
    }
  }
  .button-group .column:nth-child(2), .button-group .column:nth-child(3) {
    :deep .ant-btn {
      padding-left: 5px;
      padding-right: 5px;
      letter-spacing: -0.02rem;
      width: 100%;
    }
  }
  :deep .ant-checkbox-group > div:last-child {
    margin-bottom: 0;
  }
}
</style>
