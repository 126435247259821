export const type = {
  CALENDAR: 'calendar',
};

/**
 * Get applicaiton data by key
 * @param {type} key
 * @returns {object}
 */
export function getOptionByKey(key) {
  const data = window.localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }

  return {};
}

/**
 * Set property to selected key
 * @param {type} key
 * @param {object} options
 */
export function setOption(key, options) {
  const recentData = getOptionByKey(key);
  const object = Object.assign(recentData, options);

  window.localStorage.setItem(key, JSON.stringify(object));
}

/**
 * remove key
 * @param {type} key
 */
export function deleteKey(key) {
  window.localStorage.removeItem(key);
}

/**
 * Delete property in a key
 * @param {type} key
 * @param {string} property
 */
export function deleteKeyProperty(key, property) {
  const recentData = getOptionByKey(key);

  delete recentData[property];

  window.localStorage.setItem(key, JSON.stringify(recentData));
}
