<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-calendar ref="calendar" @panelChange="onPanelChange">
        <template #headerRender="{ value, onChange }">
          <div class="calendar-header is-size-4 has-text-weight-bold">
            <a
              class="calendar-prev m-r-2x"
              @click.prevent="onPrev(value, onChange)"
            >
              <LeftCircleFilled />
            </a>
            <a
              class="calendar-next m-r-2x"
              @click.prevent="onNext(value, onChange)"
            >
              <RightCircleFilled />
            </a>
            <span class="selected-month has-text-primary m-r-2x">
              {{ value.format($variables.dateMonthFormat) }}
            </span>
            <strong
              v-if="!isFetching && roomName"
              class="is-size-6 m-r-2x has-text-dark"
            >
              {{ $filters.truncate(roomName, 20) }}
            </strong>
          </div>
        </template>
        <template #dateFullCellRender="{ current: value }">
          <CalendarCell
            :date-obj="value"
            :position="value.day()"
            :last-position="6"
            :date="value.format('YYYY-MM-DD')"
            :value="roomCalendarData"
            class="td-date"
            @change="$emit('change')"
          />
        </template>
      </a-calendar>
    </a-spin>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue';
import CalendarCell from '@/views/calendar/components/CalendarCell';

export default {
  name: 'PerRoomCalendar',
  components: {
    LeftCircleFilled,
    RightCircleFilled,
    CalendarCell,
  },
  props: {
    dateFrom: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    isFetching: {
      type: Boolean,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    // router
    const router = useRouter();
    const route = useRoute();

    // month select
    const onPrev = (value, cb) => {
      cb(value.clone().subtract(1, 'M'));
    };
    const onNext = (value, cb) => {
      cb(value.clone().add(1, 'M'));
    };
    const onPanelChange = (dateObj) => {
      const date = dateObj.format('YYYY-MM-DD');
      router
        .push({
          query: {
            ...route.query,
            date,
          },
        })
        .then(() => {
          emit('change', date);
        });
    };

    // calendar data
    const roomCalendarData = computed(() => (props.list?.length ? props.list[0] : null));
    const roomName = computed(
      () => (roomCalendarData.value
        ? roomCalendarData.value.room.name
        : null
      ),
    );

    return {

      onPrev,
      onNext,
      onPanelChange,

      roomCalendarData,
      roomName,
    };
  },
};
</script>

<style lang="scss" scoped>
.calendar-header {
  padding-bottom: 5px;
}
:deep {
  .ant-fullcalendar-calendar-body {
    padding: 0;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
    background: transparent;
    padding: 6px 0 6px 3px;
    text-align: left;
  }
  .ant-fullcalendar-cell {
    border: 1px solid #dcdcdc;
  }
  .ant-fullcalendar-column-header-inner {
    color: #888888;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 768px) {
  .calendar-header {
    display: flex;
    align-items: center;
    font-size: 16px!important;
    strong {
      margin-right: 0!important;
      margin-left: auto;
    }
  }
}
</style>
