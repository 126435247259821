<template>
  <Modal>
    <template #default="{ hide }">
      <div>
        <div v-if="step === 1" class="columns is-multiline">
          <div class="column is-12">
            <h3 class="has-text-primary has-text-weight-bold is-size-4">
              <i class="el-icon-warning has-text-danger" /> {{ $t('title') }}
            </h3>
          </div>
          <div class="column is-12">
            <h5 class="has-text-weight-bold">
              {{ $t('Room') }}
            </h5>
            <a-radio-group :default-value="1" @change="roomModeChanged">
              <a-radio :style="radioStyle" :value="1">
                {{ $t('Delete for this room') }}
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                {{ $t('Delete for all rooms') }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="column is-12">
            <h5 class="has-text-weight-bold">
              {{ $t('Date range') }}
            </h5>
            <a-radio-group :default-value="1" @change="modeChanged">
              <a-radio :style="radioStyle" :value="1">
                Delete
                {{ $filters.date(record.blockedDate, { format: $variables.dateFormat }) }} only
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                Delete {{ $filters.date(record.firstDate, { format: $variables.dateFormat }) }}
                - {{ $filters.date(record.lastDate, { format: $variables.dateFormat }) }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="column has-text-right is-12">
            <a-button class="m-r-2x" style="min-width: 125px" :disabled="loading" @click="hide">
              {{ $t('Cancel') }}
            </a-button>
            <a-button type="danger" style="min-width: 125px" :loading="loading" @click="handleDel">
              {{ $t('delete') }}
            </a-button>
          </div>
        </div>
        <div v-if="step === 2" class="columns is-multiline">
          <div class="column is-12">
            <h3 class="has-text-secondary has-text-weight-bold is-size-4">
              {{ $t('deleted-title') }}
            </h3>
          </div>
          <div class="column is-12">
            <p>{{ $t('deleted-desc') }}</p>
          </div>
          <div class="column has-text-right is-12">
            <a-button
              class="button-secondary-outline"
              style="min-width: 125px"
              @click="
                () => {
                  handleDeleteSuccess();
                  hide();
                }
              "
            >
              {{ $t('Ok') }}
            </a-button>
          </div>
        </div>
      </div>
    </template>
    <template #handler="{ show }">
      <DeleteFilled
        class="has-text-danger"
        @click="
          () => {
            hidePopover();
            show();
          }
        "
      />
    </template>
  </Modal>
</template>

<i18n>
  {
  "en": {
    "title": "Delete blocked date?",
    "desc": "Are you use you want to delete blocked date?",
    "deleted-title": "Blocked date deleted",
    "deleted-desc": "Blocked date has been removed.",
    "Room": "Room",
    "Delete for this room": "Delete for this room",
    "Delete for all rooms": "Delete for all rooms",
    "Date range": "Date range"
  },
  "ja": {
    "title": "ブロック日にちを削除？",
    "desc": "本当にロック日にちを削除しますか？",
    "deleted-title": "ブロック日にち削除完了",
    "deleted-desc": "ブロック日にちが削除されました",
    "Room": "部屋単位",
    "Delete for this room": "指定の部屋だけのブロックを削除",
    "Delete for all rooms": "全ての部屋のブロックを削除",
    "Date range": "日付単位"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/Modal';

export default {
  name: 'DeleteBlockedDate',
  components: {
    DeleteFilled,
    Modal,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    hidePopover: {
      type: Function,
      default: () => {},
    },
    property: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
  data() {
    return {
      step: 1,
      loading: false,
      deleteMode: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      roomDeleteMode: 1,
      rooms: [],
    };
  },
  created() {
    this.getAllRooms();
  },
  methods: {
    modeChanged(e) {
      this.deleteMode = e.target.value;
    },
    handleDel() {
      this.loading = true;

      let action;
      const payload = { roomId: this.record.roomId };

      if (this.deleteMode === 1) {
        action = 'calendar/deleteBlockedDates';
        payload.id = this.record.id;
        payload.blockedDates = [this.record.blockedDate];
      } else if (this.deleteMode === 2) {
        action = 'calendar/deleteBlockedDateMany';
        payload.ids = this.record.ids;
        payload.blockedDates = this.getDateRange();
      }

      if (this.roomDeleteMode === 2) {
        payload.rooms = this.rooms.map((item) => item.id);
        action = 'calendar/deleteBlockedDateManyRooms';
      }

      this.$store.dispatch(action, payload)
        .then(() => {
          this.loading = false;
          this.step = 2;
        });
    },
    handleDeleteSuccess() {
      this.$emit('delete');
    },
    roomModeChanged(e) {
      this.roomDeleteMode = e.target.value;
    },
    getAllRooms() {
      const params = {
        propertyId: this.property.newPropertyCode,
        infinite: true,
      };

      return this.$store.dispatch('rooms/names', params).then((res) => {
        this.rooms = res.list;

        return Promise.resolve();
      });
    },
    getDateRange() {
      const dates = [];
      let currentDate = dayjs(this.record.firstDate);
      const to = dayjs(this.record.lastDate);

      while (currentDate <= to) {
        dates.push(dayjs(currentDate).format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'day');
      }

      return dates;
    },
  },
};
</script>
