<template>
  <a-checkbox-group v-model:value="localValue" :size="size" @change="handleChange">
    <div class="reservation-unavailable">
      <a-checkbox :size="size" value="blocked">
        {{ $t('Blocked / Unavailable') }}
      </a-checkbox>
    </div>
  </a-checkbox-group>
</template>

<i18n>
{
"en": {
"Blocked / Unavailable": "Blocked/Unavailable"
},
"ja": {
"Blocked / Unavailable": "ブロック/利用できません"
}
}
</i18n>

<script>
export default {
  name: 'InputBlockedDateStatus',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      localValue: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.localValue = [...nv];
      },
    },
  },
  methods: {
    handleChange(value) {
      this.localValue = value;
      this.$emit('change', this.localValue);
      this.$emit('update:value', this.localValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.reservation-booked::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $reservation-booked;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $reservation-booked;
    background-color: $reservation-booked;
  }
}

.reservation-unavailable::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $reservation-unavailable;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $reservation-unavailable;
    background-color: $reservation-unavailable;
  }
}
.reservation-same-guest::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $reservation-same-guest;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $reservation-same-guest;
    background-color: $reservation-same-guest;
  }
}
.reservation-excess-guests::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $reservation-excess-guests;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $reservation-excess-guests;
    background-color: $reservation-excess-guests;
  }
}
</style>
