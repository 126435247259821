<template>
  <a-checkbox-group v-model:value="localValue" :size="size" @change="handleChange">
    <div v-for="(status, idx) in statusOptions" :key="idx" :class="`room-status-${status}`">
      <a-checkbox :size="size" :value="status">
        {{ $t(status) }}
      </a-checkbox>
    </div>
  </a-checkbox-group>
</template>

<script>
import { STATUS } from '@/config/room-assignments';

export default {
  name: 'InputRoomAssignmentStatus',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      localValue: undefined,
      statusOptions: STATUS,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.localValue = [...nv];
      },
    },
  },
  methods: {
    handleChange(value) {
      this.localValue = value;
      this.$emit('change', this.localValue);
      this.$emit('update:value', this.localValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.room-status-requested::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $room-status-requested;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $room-status-requested;
    background-color: $room-status-requested;
  }
}

.room-status-rejected::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $room-status-rejected;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $room-status-rejected;
    background-color: $room-status-rejected;
  }
}

.room-status-assigned::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $room-status-assigned;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $room-status-assigned;
    background-color: $room-status-assigned;
  }
}

.room-status-in-progress::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $room-status-in-progress;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $room-status-in-progress;
    background-color: $room-status-in-progress;
  }
}

.room-status-no-show::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $room-status-no-show;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $room-status-no-show;
    background-color: $room-status-no-show;
  }
}

.room-status-finished::v-deep {
  margin-bottom: $bleed * 2;

  .ant-checkbox-inner {
    border-color: $room-status-finished;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $room-status-finished;
    background-color: $room-status-finished;
  }
}
</style>
