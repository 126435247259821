<template>
  <div>
    <div class="calendar-component">
      <div class="today">
        <!--today button-->
        <div>
          <a class="ant-btn ant-btn-lg button-secondary-outline" @click.prevent="changeDate(today)">
            {{ $t('Today') }} {{ $filters.date(today, { format: $variables.dateFormat }) }}
          </a>
        </div>
        <!--mobile date picker-->
        <div class="hide-calendar-desktop">
          <a-date-picker
            v-model:value="date"
            :format="$variables.dateFormat"
            @change="onDatePickerChange"
          >
            <a class="search-date">
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 1.35H12.5V0.15C12.5 0.0675 12.425 0 12.3333 0H11.1667C11.075 0 11 0.0675 11 0.15V1.35H5.66667V0.15C5.66667 0.0675 5.59167 0 5.5 0H4.33333C4.24167 0 4.16667 0.0675 4.16667 0.15V1.35H0.666667C0.297917 1.35 0 1.61813 0 1.95V14.4C0 14.7319 0.297917 15 0.666667 15H16C16.3687 15 16.6667 14.7319 16.6667 14.4V1.95C16.6667 1.61813 16.3687 1.35 16 1.35ZM15.1667 13.65H1.5V6.525H15.1667V13.65ZM1.5 5.25V2.7H4.16667V3.6C4.16667 3.6825 4.24167 3.75 4.33333 3.75H5.5C5.59167 3.75 5.66667 3.6825 5.66667 3.6V2.7H11V3.6C11 3.6825 11.075 3.75 11.1667 3.75H12.3333C12.425 3.75 12.5 3.6825 12.5 3.6V2.7H15.1667V5.25H1.5Z" fill="#008189" />
              </svg>
              <span>{{ $t('Search date/month') }}</span>
            </a>
          </a-date-picker>
        </div>
      </div>
      <div class="calendar-nav">
        <!--Previous 2 weeks button -->
        <div>
          <a class="prev" @click.prevent="changeDate(previousTwoWeeks)">
            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_806_984)">
                <path d="M23.41 16.59L18.83 12L23.41 7.41L22 6L16 12L22 18L23.41 16.59Z" fill="#008189" />
              </g>
              <g clip-path="url(#clip1_806_984)">
                <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="#008189" />
              </g>
              <defs>
                <clipPath id="clip0_806_984">
                  <rect width="24" height="24" fill="white" transform="translate(8)" />
                </clipPath>
                <clipPath id="clip1_806_984">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            {{ $t('Previous 2 weeks') }}
          </a>
        </div>

        <!--Previous day button -->
        <div>
          <a class="prev" @click.prevent="changeDate(previousDay)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6782_10936)">
                <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="#008189" />
              </g>
              <defs>
                <clipPath id="clip0_6782_10936">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {{ $mq==='mobile' ? $t('Prev') : $t('Previous day') }}
          </a>
        </div>

        <!--desktop date picker -->
        <div class="hide-calendar-mobile">
          <a-date-picker
            v-model:value="date"
            :format="$variables.dateFormat"
            @change="onDatePickerChange"
          >
            <a class="search-date">
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 1.35H12.5V0.15C12.5 0.0675 12.425 0 12.3333 0H11.1667C11.075 0 11 0.0675 11 0.15V1.35H5.66667V0.15C5.66667 0.0675 5.59167 0 5.5 0H4.33333C4.24167 0 4.16667 0.0675 4.16667 0.15V1.35H0.666667C0.297917 1.35 0 1.61813 0 1.95V14.4C0 14.7319 0.297917 15 0.666667 15H16C16.3687 15 16.6667 14.7319 16.6667 14.4V1.95C16.6667 1.61813 16.3687 1.35 16 1.35ZM15.1667 13.65H1.5V6.525H15.1667V13.65ZM1.5 5.25V2.7H4.16667V3.6C4.16667 3.6825 4.24167 3.75 4.33333 3.75H5.5C5.59167 3.75 5.66667 3.6825 5.66667 3.6V2.7H11V3.6C11 3.6825 11.075 3.75 11.1667 3.75H12.3333C12.425 3.75 12.5 3.6825 12.5 3.6V2.7H15.1667V5.25H1.5Z" fill="#008189" />
              </svg>
              <span>{{ $t('Search date/month') }}</span>
            </a>
          </a-date-picker>
        </div>

        <!--mobile division-->
        <div class="division hide-calendar-desktop" />

        <!--Next day-->
        <div>
          <a class="next" @click.prevent="changeDate(nextDay)">
            {{ $mq==='mobile' ? $t('Next') : $t('Next day') }}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6781_10920)">
                <path d="M8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6L8.59 7.41Z" fill="#008189" />
              </g>
              <defs>
                <clipPath id="clip0_6781_10920">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(24 24) rotate(-180)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <!--Next 2 weeks button -->
        <div>
          <a class="next" @click.prevent="changeDate(nextTwoWeeks)">
            {{ $t('Next 2 weeks') }}
            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_806_984)">
                <path d="M8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6L8.59 7.41Z" fill="#008189" />
              </g>
              <g clip-path="url(#clip1_806_984)">
                <path d="M16.59 7.41L21.17 12L16.59 16.59L18 18L24 12L18 6L16.59 7.41Z" fill="#008189" />
              </g>
              <defs>
                <clipPath id="clip0_806_984">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(24 24) rotate(-180)"
                  />
                </clipPath>
                <clipPath id="clip1_806_984">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(32 24) rotate(-180)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <!--calendar-->
    <div v-if="init" class="calendar-wrap">
      <div v-if="isFetching" class="loader-wrap">
        <a-spin />
      </div>
      <div
        v-else
        class="calendar-outer"
      >
        <a-table
          :pagination="false"
          :data-source="localList"
          :scroll="scroll"
          :loading="isFetching"
          bordered
        >
          <a-table-column
            key="roomName"
            :width="$mq === 'mobile' ? '100px' : '100px'"
            fixed="left"
          >
            <template #title>
              <div class="has-text-primary has-text-weight-bold pl-2 th-room-name">
                {{ $t('Room Name') }}
              </div>
            </template>
            <template #default="{ record }">
              <div
                class="pl-2 td-room-name"
                @click="$emit('select-room', record.room.id)"
              >
                <!--<div v-if="$mq !== 'mobile'" class="abbreviation has-text-grey">-->
                <!--{{ record.property.abbreviation }}-->
                <!--</div>-->
                <div :title="record.room.name">
                  {{ $filters.truncate(record.room.name, 20) }}
                </div>
              </div>
            </template>
          </a-table-column>
          <a-table-column
            v-for="(date, position) in head"
            :key="date.date"
            :class="getColumnClass(date.date)"
          >
            <template #title>
              <div class="th-date">
                <div
                  class="has-text-grey has-text-weight-normal"
                  :class="{'has-text-centered is-size-9': $mq === 'mobile',
                           'is-size-8': $mq !== 'mobile'}"
                >
                  {{ date.month }}
                </div>
                <div
                  class="has-text-centered has-text-primary has-text-weight-bold"
                  :class="{'has-text-centered is-size-7': $mq === 'mobile',
                           'is-size-6': $mq !== 'mobile'}"
                >
                  {{ date.day }}
                </div>
                <div
                  class="has-text-centered has-text-grey has-text-weight-normal"
                  :class="{'has-text-centered is-size-8': $mq === 'mobile',
                           'is-size-7': $mq !== 'mobile'}"
                >
                  {{ date.week }}
                </div>
              </div>
            </template>
            <template #default="{ record }">
              <CalendarCell
                :key="date.date"
                :position="position"
                :last-position="head.length - 1"
                :date="date.date"
                :value="record"
                class="td-date"
                :class="getColumnClass(date.date)"
                @change="$emit('change')"
              />
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Room Name": "Room Name",
"Previous 2 weeks": "Previous 2 weeks",
"Previous day": "Previous day",
"Search date/month":"Search date/month",
"Next day": "Next day",
"Next 2 weeks":"Next 2 weeks",
"Prev":"Prev",
"Next":"Next",
"Today": "Today"
},
"ja": {
"Room Name": "部屋番号",
"Previous 2 weeks": "2週間前へ",
"Previous day": "1日前へ",
"Search date/month":"日付を指定",
"Next day": "1日後へ",
"Next 2 weeks":"2週間後へ",
"Prev":"1日前へ",
"Next":"1日後へ",
"Today": "本日"
}
}
</i18n>

<script>
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import CalendarCell from '@/views/calendar/components/CalendarCell';

export default {
  name: 'CalendarTable',
  components: { CalendarCell },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    days: {
      type: Number,
      default: 14,
    },
    scroll: {
      type: Object,
      default() {
        return {
          x: 1500,
          y: 475,
        };
      },
    },
    isFetching: {
      type: Boolean,
    },
    init: {
      type: Boolean,
    },
  },
  emits: ['change', 'select-room'],
  data() {
    return {
      date: undefined,
    };
  },
  computed: {
    head() {
      // load previous 2 days
      const fromDate = dayjs(this.dateFrom).subtract(2, 'd');
      const _head = [];
      for (let i = 0; i < this.days; i += 1) {
        const _date = i > 0 ? fromDate.add(i, 'days').format('YYYY-MM-DD') : fromDate.format('YYYY-MM-DD');
        _head.push({
          date: _date,
          day: dayjs(_date).locale(this.$i18n.locale).format('DD'),
          week: dayjs(_date).locale(this.$i18n.locale).format('ddd'),
          month: dayjs(_date).locale(this.$i18n.locale).format('MMM'),
        });
      }
      return _head;
    },
    today() {
      return dayjs(new Date()).format('YYYY-MM-DD');
    },
    previousTwoWeeks() {
      return dayjs(this.dateFrom)
        .add(-14, 'days')
        .format('YYYY-MM-DD');
    },
    nextTwoWeeks() {
      return dayjs(this.dateFrom).add(14, 'days').format('YYYY-MM-DD');
    },
    previousDay() {
      return dayjs(this.dateFrom)
        .add(-1, 'days')
        .format('YYYY-MM-DD');
    },
    nextDay() {
      return dayjs(this.dateFrom).add(1, 'days').format('YYYY-MM-DD');
    },
    localList() {
      return this.list.map((item, index) => {
        const clonedItem = cloneDeep(item);
        clonedItem.key = index;
        return clonedItem;
      });
    },
  },
  methods: {
    onDatePickerChange() {
      const date = this.date
        ? this.$filters.date(this.date, { format: 'YYYY-MM-DD' })
        : undefined;
      this.changeDate(date);
    },
    changeDate(date) {
      this.date = undefined;
      this.$router
        .push({
          query: {
            ...this.$route.query,
            date,
          },
        })
        .then(() => {
          this.$emit('change', date);
        });
    },
    getColumnClass(date) {
      return date === this.today ? 'is-current-date' : `is-${date}`;
    },
  },
};
</script>

<style lang="scss" scoped>
$calendar-header-bg:#f5f5f5;
$current-date-color: #008189;
$calendar-break-max: 1439px;
$calendar-break-min: 1440px;
:deep {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-table-thead > tr > th {
    background-color: $calendar-header-bg;
    &.is-current-date {
      box-shadow: inset 0 0 0 2px $current-date-color;
    }
  }
  .ant-table table {
    border-radius: 0;
  }
  .ant-table-header {
    background-color: $calendar-header-bg;
  }
  .ant-table-fixed-left, .ant-table-fixed-right {
    z-index: 101;
  }
}
.search-date {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: block;
    color: #888888;
    border-bottom: 1px solid $current-date-color;
  }
}
.division {
  width: 2px;
  height: 14px;
  background-color:  $current-date-color;
  margin: 0 5px;
}

.calendar-component {
  width: 100%;
  @media screen and (min-width: $calendar-break-min) {
    display: flex;
    margin-bottom: 20px;
  }

  .today {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
    padding-top: 24px;
    border-top: 1px solid #DCDCDC;
    > div {
      flex: 1 1 50%;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      .button-secondary-outline {
        width: 100%;
      }
    }
    @media screen and (min-width: $calendar-break-min) {
      margin: 0;
      padding-top:0;
      border-top-width: 0;
    }
  }

  .calendar-nav {
    flex: 1 1 auto;
    margin-bottom: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: -0.05em;
    > div > a {
      display: flex;
      align-items: center;
    }
    @media screen and (min-width: $calendar-break-min) {
      margin-bottom: 0;
      font-size: 14px;
      letter-spacing: 0;
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.calendar-wrap {
  width: 100%;
  @include tablet {
    max-width: calc(100vw - 270px);
  }
}

.calendar-outer {

  .th-room-name {
    line-height: 1;
    display: flex;
    align-items: center;
    vertical-align: middle;
    background-color: #f5f5f5;
    padding: 3px;
    height: 51px;
    white-space: nowrap;
  }

  .td-room-name {
    padding: 3px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: $calendar-row-height;
    font-size: 14px;
    background-color: #ffffff;
    cursor: pointer;

    .abbreviation {
      font-size: 12px;
    }
  }

  :deep .ant-table-header-column {
    width: 100%;
  }

  .th-date {
    line-height: 1;
    vertical-align: middle;
    // background-color: #f5f5f5;
    padding: 3px;
    text-transform: uppercase;
    width: 100%;
    padding-top: 7px;
    @include tablet {
      padding-top: 3px;
    }
  }

  .td-date {
    cursor: default;
    height: $calendar-row-height;
    //background-color: #ffffff;
    position: relative;
  }

}
@media screen and (max-width: $calendar-break-max) {
  .hide-calendar-mobile {
    display: none!important;
  }
}
@media screen and (min-width: $calendar-break-min) {
  .hide-calendar-desktop {
    display: none!important;
  }
}
.loader-wrap {
  width: 100%;
  height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-wrap {
  min-height: 540px;
}
</style>
