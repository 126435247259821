<template>
  <div class="columns form is-multiline search-form">
    <div class="column is-12 pb-0">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        displayed-text="abbreviation"
        style="width: 100%"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-12">
      <InputRoom
        v-model:value="roomId"
        :size="size"
        :property-id="propertyId"
        style="width: 100%"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-12 py-0">
      <div class="p-1 pl-2">
        <a-checkbox
          :checked="showName"
          @change="(e) => toggleShowName(e.target.checked)"
        >
          {{ $t('Show guest & housekeeper names') }}
        </a-checkbox>
      </div>
    </div>
    <div v-permission="['view-reservation']" class="column is-12">
      <div class="filter-group-title mb-2 p-1 pl-2">
        <a-checkbox
          :checked="reservationChecked"
          @change="(e) => handleChangeReservation(e.target.checked)"
        >
          {{ $t('Reservation') }}
        </a-checkbox>
      </div>
      <div class="pl-2">
        <InputReservationStatus v-model:value="reservation" @change="debounceQuery" />
      </div>
    </div>
    <div v-permission="['view-blocked-date']" class="column is-12">
      <div class="filter-group-title mb-2 p-1 pl-2">
        <a-checkbox
          :checked="reservationChecked"
          disabled
          @change="(e) => handleChangeReservation(e.target.checked)"
        >
          {{ $t('Reservation') }}
        </a-checkbox>
      </div>
      <div class="pl-2">
        <InputBlockedDateStatus v-model:value="reservation" @change="debounceQuery" />
      </div>
    </div>
    <div class="column is-12">
      <div class="filter-group-title mb-2 p-1 pl-2">
        <a-checkbox
          :checked="assignmentChecked"
          @change="(e) => handleChangeStatus(e.target.checked)"
        >
          {{ $t('Housekeeping') }}
        </a-checkbox>
      </div>
      <div class="pl-2">
        <InputRoomAssignmentStatus v-model:value="status" @change="debounceQuery" />
      </div>
    </div>
    <div class="column is-12">
      <a-button
        v-show="roomId || propertyId || date || !hasFilterStatus || !hasFilterReservation"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Clear": "Clear",
    "Housekeeping": "Housekeeping",
    "Reservation": "Reservation",
    "Show guest & housekeeper names": "Show guest & housekeeper names"
  },
  "ja": {
    "Clear": "クリア",
    "Housekeeping": "ハウスキーピング",
    "Reservation": "予約",
    "Show guest & housekeeper names": "Show guest & housekeeper names"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';
import InputRoomAssignmentStatus from '@/components/InputRoomAssignmentStatus';
import InputReservationStatus from '@/components/InputReservationStatus';
import InputRoom from '@/components/InputRoom';
import { STATUS } from '@/config/room-assignments';
import { CALENDAR_STATUS } from '@/config/reservations';
import checkPermission from '@/utils/permission';
import { type, setOption, getOptionByKey } from '@/utils/application-data';
import InputBlockedDateStatus from '@/components/InputBlockedDateStatus';

export default {
  name: 'SearchForm',
  components: {
    InputBlockedDateStatus,
    InputRoom,
    InputRoomAssignmentStatus,
    InputProperty,
    InputReservationStatus,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['filter'],
  data() {
    return {
      roomId: undefined,
      propertyId: undefined,
      status: undefined,
      reservation: undefined,
      assignmentChecked: undefined,
      reservationChecked: undefined,
    };
  },
  computed: {
    hasFilterStatus() {
      if (this.status && this.status.length >= Object.keys(STATUS).length) {
        return true;
      }
      return false;
    },
    hasFilterReservation() {
      if (this.reservation && this.reservation.length >= CALENDAR_STATUS.length) {
        return true;
      }
      return false;
    },
    showName() {
      return this.$store.state.calendar.showName;
    },
  },
  watch: {
    status: {
      handler(val) {
        if (val && val.length >= Object.keys(STATUS).length) {
          this.assignmentChecked = true;
        } else {
          this.assignmentChecked = false;
        }
      },
    },
    reservation: {
      handler(val) {
        if (val && val.length >= CALENDAR_STATUS.length) {
          this.reservationChecked = true;
        } else {
          this.reservationChecked = false;
        }
      },
    },
  },
  created() {
    const query = {};
    if (!this.$route.query.reservation && !this.$route.query.status) {
      if (checkPermission(['view-reservation'])) {
        query.reservation = CALENDAR_STATUS.map((el) => el.value).join(',');
      }

      query.status = Object.values(STATUS).join(',');
    }
    this.$router
      .push({
        query: {
          ...this.$route.query,
          ...query,
        },
      })
      .then(() => {
        this.roomId = this.$route.query.roomId || undefined;
        this.propertyId = this.$route.query.propertyId || undefined;
        this.status = this.$route.query.status ? this.$route.query.status.split(',') : undefined;
        this.reservation = this.$route.query.reservation
          ? this.$route.query.reservation.split(',')
          : undefined;
        if (this.propertyId) {
          this.$emit('filter');
        }
      });

    this.getInitialCalendarData();
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(async function () {
      await this.$router.push({
        query: {
          roomId: this.roomId,
          propertyId: this.propertyId,
          status: this.status ? this.status.join(',') : undefined,
          reservation: this.reservation ? this.reservation.join(',') : undefined,
          date: this.$route.query.date,
        },
      });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.roomId = undefined;
      this.propertyId = undefined;
      this.status = Object.values(STATUS);
      this.reservation = CALENDAR_STATUS.map((el) => el.value);
      this.assignmentChecked = undefined;
      this.reservationChecked = undefined;
      this.debounceQuery();
    },
    handleChangeReservation(val) {
      this.reservationChecked = val;
      if (val) {
        this.reservation = CALENDAR_STATUS.map((status) => status.value);
      } else {
        this.reservation = undefined;
      }
      this.debounceQuery();
    },
    handleChangeStatus(val) {
      this.assignmentChecked = val;
      if (val) {
        this.status = Object.values(STATUS);
      } else {
        this.status = undefined;
      }
      this.debounceQuery();
    },
    toggleShowName(status) {
      this.$store.dispatch('calendar/changeShowName', {
        showName: status,
      });
      setOption(type.CALENDAR, { showName: status });
    },
    getInitialCalendarData() {
      const data = getOptionByKey(type.CALENDAR);
      const initialValue = data.showName === undefined ? true : data.showName;

      this.toggleShowName(initialValue);
    },
    setRoomId(id) {
      this.roomId = id;
      this.debounceQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-group-title::v-deep {
  background-color: $tab-head-bg;

  span {
    color: $beige-lighter;
    font-weight: $title-weight;
  }

  .ant-checkbox-inner {
    border-color: $rtx-secondary;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $rtx-secondary;
    background-color: $rtx-secondary;
  }
}
.search-form {
  @include tablet {
    width: 220px;
  }
}
</style>
