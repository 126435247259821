<template>
  <div v-permission="['modify-room-assignment']">
    <EditFilled
      class="has-text-success-dark m-r-2x"
      @click="
        () => {
          $emit('show-assignment-form');
        }
      "
    />
    <DelRoomAssignment :record="assignment" @delete="$emit('assignment-changed')">
      <template #handler="{ show }">
        <DeleteFilled
          class="has-text-danger"
          @click="
            () => {
              $emit('hide-popover');
              show();
            }
          "
        />
      </template>
    </DelRoomAssignment>
  </div>
</template>

<script>
import { DeleteFilled, EditFilled } from '@ant-design/icons-vue';
import DelRoomAssignment from '@/views/room-assignment/components/DelRoomAssignment';

export default {
  name: 'ModifyAssignmentButtons',
  components: {
    DelRoomAssignment,
    DeleteFilled,
    EditFilled,
  },
  props: {
    assignment: {
      type: Object,
      default: null,
    },
  },
  emits: ['assignment-changed', 'hide-popover', 'show-assignment-form'],
};
</script>

<style></style>
