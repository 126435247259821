<template>
  <div
    :class="`${hasAssignment ? assignment.status : ''}`"
    class="calendar-cell"
    @click="onClicked"
  >
    <div v-if="dateObj" class="date">
      {{ dateObj.format('DD') }}
    </div>
    <!-- The start of guest resrvation block -->
    <template v-if="hasGuest && canShowGuest && canShowExcessGuest">
      <div
        class="hasReservationInfo is-cursor-pointer small-block"
        :class="`
          ${hasGuest && 'hasGuest booked'}
          ${hasSameGuest && 'same-guest'}
          ${hasExcessGuests && 'excess-guests'}
          ${isGuestFirstDate && 'radius-left'}
          ${isGuestLastDate && 'radius-right'}
          ${position === 0 && !isGuestFirstDate && !isGuestLastDate && 'non-overlap-block-start'}
          ${
          position === 0 &&
          isGuestLastDate &&
          !isGuestFirstDate &&
          'non-overlap-block-start-for-last-date'
        }
          ${position === lastPosition && 'non-overlap-block-end'}
        `"
        @click="goToReservation(guest.id)"
      >
        <div
          v-if="isGuestFirstDate || position === 0"
          class="has-text-weight-bold is-size-8 small-block-text"
          :title="guestName"
        >
          {{ $filters.truncate(guestName, 20) }}
        </div>
      </div>
    </template>
    <template v-if="!!checkoutGuest">
      <div
        class="checkoutGuest
        hasReservationInfo
        is-cursor-pointer
        small-block
        hasGuest
        booked
        radius-right"
        @click="goToReservation(checkoutGuest.id)"
      >
        <div
          class="has-text-weight-bold is-size-8 small-block-text"
          :title="checkoutGuestName"
        >
          {{ $filters.truncate(checkoutGuestName, 20) }}
        </div>
      </div>
    </template>
    <!-- The end of guest reservation block -->

    <!-- The start of unavailable date block -->
    <a-popover
      v-if="hasBlockedDate || hasBlockedDateBeforeDateFrom"
      v-model:visible="popoverBlockedDateVisible"
      title=""
      trigger="click"
    >
      <template #content>
        <div class="assignment-detail">
          <div class="columns is-mini-gap is-mobile is-vcentered">
            <div class="column">
              <div class="has-text-primary has-text-weight-bold is-size-5">
                {{ $t('Blocked dates') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="is-size-6">
                <DelBlockedDate
                  :record="blockedDatePopupData"
                  :hide-popover="hidePopover"
                  :property="value.property"
                  @delete="$emit('change')"
                />
              </div>
            </div>
          </div>
          <table class="assignment-detail-table">
            <tbody>
              <tr>
                <th>{{ $t('Property') }}</th>
                <td>{{ value.property.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('Room') }}</th>
                <td>{{ value.room.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('Date') }}</th>
                <td>
                  {{
                    blockedDatePopupData &&
                      $filters.date(blockedDatePopupData.blockedDate, {
                        format: $variables.dateFormat,
                      })
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ $t('Reason') }}</th>
                <td>{{ blockedDatePopupData && blockedDatePopupData.reason }}</td>
              </tr>
              <tr>
                {{ blockedDatePopupData.lowBlock }}
                <br>

                {{ blockedDatePopupData.highBlock }}
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div
        class="hasReservationInfo is-cursor-pointer small-block"
        :class="`
          ${hasBlockedDate && 'hasBlockedDate unavailable'}
          ${isUnavailableStart && !hasBlockedDateBeforeDateFrom && 'radius-left'}
          ${isUnavailableEnd && position !== lastPosition && 'radius-right'}
          ${position === 0 && !isUnavailableStart && 'non-overlap-block-start'}
          ${position === lastPosition && 'non-overlap-block-end'}
          ${
          hasBlockedDateBeforeDateFrom &&
          'hasBlockedDate half-block non-overlap-block-start radius-right unavailable'
        }
        `"
      >
        <div
          v-show="isUnavailableTextShowed || position === 0"
          class="has-text-weight-bold has-text-white is-size-8 small-block-text"
          :title="$t('Unavailable')"
        >
          {{ $t('Unavailable') }}
        </div>
      </div>
    </a-popover>
    <!-- The end of unavailable data block -->

    <!-- The start of room assignment block -->
    <a-popover
      v-if="hasAssignment"
      v-model:visible="popoverAssignmentVisible"
      title=""
      trigger="click"
      @visibleChange="handleAssignmentPopup"
    >
      <template v-if="isInternal" #content>
        <div class="assignment-detail">
          <div class="columns is-mini-gap is-mobile is-vcentered">
            <div class="column is-6">
              <div class="has-text-primary has-text-weight-bold is-size-5">
                {{ housekeeperCompanyName }}
              </div>
              <div class="is-size-7">
                {{ housekeeperName }}
              </div>
            </div>
            <div class="column has-text-right is-6">
              <div class="is-size-8 m-b">
                {{ $t(assignment.status) }}
              </div>
              <ModifyAssignmentButtons
                class="is-size-6"
                :assignment="assignment"
                @show-assignment-form="showRoomAssignmentForm"
                @assignment-changed="
                  () => {
                    $emit('change');
                  }
                "
                @hide-popover="hidePopover"
              />
            </div>
          </div>
          <table class="assignment-detail-table">
            <tbody>
              <tr>
                <th>{{ $t('Property') }}</th>
                <td>{{ value.property.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('Room') }}</th>
                <td>{{ value.room.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('Date') }}</th>
                <td>
                  {{ $filters.date(assignment.cleaningDate, { format: $variables.dateFormat }) }}
                </td>
              </tr>
              <tr>
                <th>{{ $t('Cleaning time') }}</th>
                <td>{{ assignment.cleaningTime || '-' }}</td>
              </tr>
              <tr>
                <th>{{ $t('Remarks') }}</th>
                <td>{{ assignment && assignment.remarks }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-else #content>
        <div v-if="!isFetchingAssignmentDetail" class="assignment-detail">
          <div class="columns is-mini-gap is-mobile is-vcentered">
            <div class="column is-6">
              <div class="has-text-primary has-text-weight-bold is-size-5">
                {{ value.room.name }}
              </div>
            </div>
            <div class="column has-text-right is-6">
              <div class="is-size-8">
                {{ $t(assignment.status) }}
              </div>
              <ModifyAssignmentButtons
                class="is-size-6"
                :assignment="assignment"
                @show-assignment-form="showRoomAssignmentForm"
                @assignment-changed="
                  () => {
                    $emit('change');
                  }
                "
                @hide-popover="hidePopover"
              />
            </div>
          </div>
          <table class="assignment-detail-table">
            <tbody>
              <tr>
                <th>{{ $t('Housekeeping company') }}</th>
                <td>{{ housekeeperCompanyName }}</td>
              </tr>
              <tr>
                <th>{{ $t('Housekeeper') }}</th>
                <td>{{ housekeeperName }}</td>
              </tr>
              <tr>
                <th>{{ $t('Property') }}</th>
                <td>{{ value.property.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('Cleaning time') }}</th>
                <td>
                  {{
                    $filters.date(`${assignment.cleaningDate} ${assignment.cleaningTime}`, {
                      format: $variables.datetimeFormat,
                    })
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ $t('Same day checkin') }}</th>
                <td>
                  {{ assignmentDetail.sameDayCheckin ? $t('has-checkin') : $t('has-no-checkin') }}
                </td>
              </tr>
              <tr>
                <th>{{ $t('Checkout status') }}</th>
                <td>{{ checkoutStatus }}</td>
              </tr>
              <tr>
                <th>{{ $t('Remarks') }}</th>
                <td>{{ assignment && assignment.remarks }}</td>
              </tr>
            </tbody>
          </table>
          <div class="columns is-mini-gap is-mobile is-vcentered">
            <div class="column has-text-right m-t-2x">
              <a-button
                size="large"
                style="min-width: 140px"
                type="primary"
                @click="handleTaskList"
              >
                {{ $t('Open Task List') }}
              </a-button>
            </div>
          </div>
        </div>
      </template>
      <div
        class="has-assignment has-text-black is-cursor-pointer"
      >
        <div
          class="assignment-summary-title has-text-weight-bold is-size-8"
          :title="housekeeperName"
        >
          {{ housekeeperName }}
        </div>
      </div>
    </a-popover>
    <!-- The end of room assignment block -->

    <Modal ref="roomAssignmentForm" v-draggable :mask="false">
      <template #default="{ hide }">
        <RoomAssignmentForm
          :value="assignmentForm"
          @close="
            () => {
              hide();
              $emit('change');
            }
          "
          @cancel="hide"
        />
      </template>
    </Modal>
    <Modal ref="blockedDateForm" v-draggable :mask="false">
      <template #default="{ hide }">
        <BlockedDateForm
          :value="blockedDateForm"
          @close="
            () => {
              hide();
              $emit('change');
            }
          "
          @cancel="hide"
        />
      </template>
    </Modal>
  </div>
</template>

<i18n src="@/locales/components/calendar-cell.json"></i18n>

<script>
import Modal from '@/components/Modal';
import roles from '@/components/mixins/roles';
import checkPermission from '@/utils/permission';
import RoomAssignmentForm from '@/views/room-assignment/components/RoomAssignmentForm';
import BlockedDateForm from '@/views/calendar/components/BlockDateForm';
import DelBlockedDate from '@/views/calendar/components/DelBlockedDate';
import ModifyAssignmentButtons from '@/views/calendar/components/ModifyAssignmentButtons';
import CalendarCellComputed from '@/components/mixins/CalendarCellComputed';

export default {
  name: 'CalendarCell',
  components: {
    RoomAssignmentForm,
    Modal,
    ModifyAssignmentButtons,
    BlockedDateForm,
    DelBlockedDate,
  },
  mixins: [roles, CalendarCellComputed],
  props: {
    dateObj: {
      type: Object, // moment obj
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      default: 0,
    },
    lastPosition: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change'],
  data() {
    return {
      popoverAssignmentVisible: false,
      popoverBlockedDateVisible: false,
      isFetchingAssignmentDetail: false,
      assignmentDetail: {},
    };
  },
  computed: {
    checkoutStatus() {
      if (!this.hasAssignment || this.assignmentDetail.checkoutStatus === null) {
        return 'N.A.';
      }

      return this.assignmentDetail.checkoutStatus === true
        ? this.$t('checkout-yes')
        : this.$t('checkout-no');
    },
  },
  methods: {
    onClicked() {
      if (
        !this.hasAssignment
          && !this.hasGuest
          && !this.hasBlockedDate
          && !this.hasBlockedDateBeforeDateFrom
          && checkPermission(['add-room-assignment'])
      ) {
        this.showRoomAssignmentForm();
      }
    },
    hidePopover() {
      this.popoverAssignmentVisible = false;
      this.popoverBlockedDateVisible = false;
    },
    showRoomAssignmentForm() {
      this.hidePopover();
      this.$refs.roomAssignmentForm.show();
    },
    showBlockedDateForm() {
      this.hidePopover();
      this.$refs.blockedDateForm.show();
    },
    handleTaskList() {
      this.$router.push({ name: 'housekeeper-tasks' });
    },
    goToReservation(id) {
      if (id) {
        const routeData = this.$router.resolve({ name: 'reservation-basic-info', params: { id } });
        window.open(routeData.href, '_blank');
      }
    },
    handleAssignmentPopup(val) {
      if (val && this.hasAssignment) {
        this.isFetchingAssignmentDetail = true;
        this.$store.dispatch('room-assignments/get', { id: this.assignment.id })
          .then((resp) => {
            this.assignmentDetail = resp;
            this.isFetchingAssignmentDetail = false;
          });
      }
    },
  },
};
</script>

<style src="@/assets/css/calendar-cell.scss" lang="scss" scoped></style>
<style lang="scss" scoped>
.ant-table-tbody > tr >td:nth-child(2) .hasGuest.checkoutGuest {
  left: 0;
  display: flex;
  width: 50%!important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
